import {useEffect, useState} from 'react';
import Lottie from 'lottie-react';
// import members from 'json/teamMembers.json';
import onScreen from 'libraries/onScreen';
import TeamMember from 'TeamMember';

export default function() {
  let [flowerEl, setFlowerEl] = useState(null);
  let [membersData, setMembersData] = useState([]);

  let loadFlower = async () => {
    const flower = await import('json/lottie/flower.json');

    setFlowerEl(() => 
      <Lottie 
        // path='lottie/flower.json' loop="true" 
        animationData={flower} loop="true" 
        className="me-3 filter-body-color position-relative end-50" 
        style={{transform: 'scaleX(-1)', width: '7rem', bottom: '.675rem'}} 
      />
    )
  }

  let loadMembersData = async () => {
    let data = await import('json/teamMembers.json');

    setMembersData(data.default);
  }

  useEffect(() => {
    onScreen.is('.team-members', () => {
      loadMembersData()
    });

    onScreen.is('.team-text', () => {
      loadFlower();
    });
  }, []);

  return (
    <div className="team-members">
      <div className="row gx-4 gx-lg-5">
        {membersData.map(member =>
          <div key={member.name} className="col-12 col-sm-6 col-md-auto mb-4 mb-md-0">
            <TeamMember data={member} />
          </div>
        )}

        <div 
          className="col-auto d-none d-lg-flex flex-column align-self-end"
        >
          <div className="ms-3" data-reveal data-reveal-layers={4} data-reveal-delay={1000}>
            <div className="team-text position-relative">
              <div className="position-absolute bottom-0 start-0">
                {flowerEl}
              </div>
              <h1 className="display-3 mb-0">
                  <div>THE</div>
                  <div>PEOPLE</div>
                  <div>BEHIND</div>
                  <div className="ms-4 ps-1">T.</div>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}