import {useEffect, useRef} from 'react';
import anime from 'animejs/lib/anime.es.js';
import onScreen from 'libraries/onScreen';
import SVG from 'react-inlinesvg';
import Lottie from 'lottie-react';
import bubble from 'json/lottie/bubble.json';

export default function({title, text, icon, number, delay}) {
  let item = useRef(null);
  delay = parseInt(delay) || 0;

  useEffect(() => {
    onScreen.is(item.current, () => {
      anime({
        targets: item.current.querySelector('.icon'),
        opacity: {value: 1, easing: 'easeInOutSine', duration: 500},
        translateY: [-60, 0],
        duration: 1200,
        delay: delay+500,
        easing: 'easeOutBounce',
      });
    })
  }, []);

  return (
    <div className="box-list-item d-flex flex-column align-items-start h-100" ref={item}>
      {icon &&
        <div 
          className="icon d-flex flex-center position-relative mb-5 border w-100"
          style={{
            height: '200px',
            opacity: 0
          }}
        >
          {number &&
            <div 
              className="number position-absolute start-0 bottom-0 translate-middle-x d-inline-flex flex-center z-index-1 bg-body-bg border-bottom p-1 text-body-bg"
              style={{
                marginBottom: '-1px'
              }}
            >
              <Lottie 
                animationData={bubble} autostart="true" loop="true" 
                className="bubble position-absolute top-50 start-50 translate-middle filter-color-1" 
                style={{
                  width: '130px',
                  height: '130px'
                }}
              />
              <span 
                className="fs-5 d-flex flex-center p-2 position-relative"
                style={{
                  marginBottom: '-1px',
                  width: '40px', 
                  height: '40px',
                }}
              >
                {number}
              </span>
            </div>
          }

          <SVG src={icon} width="55px" className="position-relative z-index-1" />
        </div>
      }

      {title &&
        <h4 className="w-100" data-reveal data-reveal-delay={delay + 100}>
          {title}
        </h4>
      }

      {title &&
        <p data-transition="right" data-transition-delay={delay + 500}>
          {text}
        </p>
      }
    </div>
  )
}