import {useEffect, useRef} from 'react';
import anime from 'animejs/lib/anime.es.js';
import onScreen from 'libraries/onScreen';

export default function({data}) {
  let member = useRef(null);

  useEffect(() => {
    onScreen.is(member.current, () => {
      anime({
        targets: member.current.querySelectorAll('.cells'),
        opacity: 0,
        duration: 900,
        easing: 'easeInOutExpo', 
        delay: anime.stagger(75),
      });

      anime({
        targets: member.current.querySelectorAll('.member-body'),
        opacity: 1,
        translateX: [-50, 0],
        duration: 2500,
        delay: 1000,
      });
    });
  }, []);

  return (
    <div 
      ref={member} 
      className="member position-relative mb-3" 
      data-transition-out="right"
    >
      <div className="position-relative">
        <img src={data.photo} alt={data.desc} width="100%" />
        <div className="d-flex flex-wrap position-absolute top-0 start-0 w-100 h-100">
          {[...Array(12)].map((_, i) => 
            <div key={i} className="col-4 bg-body cells position-relative"></div>
          )}
        </div>
      </div>
      <div 
        className="member-body py-4 px-4 text-light bg-color-1 position-absolute bottom-0 ms-n3 mb-n3"
        style={{opacity: 0}}
      >
        <h6 
          className="mb-0 d-block text-dark opacity-80 px-3 position-relative" 
          style={{letterSpacing: '1px'}}
        >
          {data.title}
        </h6>
        <h5 
          className="mb-0 px-4 position-relative" 
          style={{letterSpacing: '2px'}}
        >
          {data.name}
        </h5>
      </div>
    </div>
  )
}