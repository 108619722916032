import {useEffect} from 'react';

export default function({className, items, transition, delay, duration, ...rest}) {
  delay = parseInt(delay) || 0;

  return (
    <ul 
      className={"list" + (className ? ' ' + className : '')} 
      {...rest}
    >
      {items.map((item, key) => 
        <li 
          key={key}
          data-transition={transition || ''} data-transition-delay={delay +=100} data-transition-duration={duration}
        >
          {item}
        </li>
      )}
    </ul>
  )
}