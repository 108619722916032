import anime from 'animejs/lib/anime.es.js';

function Indicator(container, options = {}) {
  let _container = container;
  let _options = options;
  let containerEl;
  let indicatorEl;

  /**
   * Add styling to elements
   */
  let addStyle = (element, style = {}) => {
    for (const property in style)
      element.style[property] = style[property];
  }

  /**
   * Update indicator position to the active element
   */
  let updatePosition = (active, animate = true) => {
    anime({
      targets: indicatorEl,
      duration: animate ? _options.duration || 1200 : 0,
      // opacity: 1,
      width: active.offsetWidth + 'px',
      height: active.offsetHeight + 'px',
      top: active.offsetTop + 'px',
      left: active.offsetLeft + 'px',
      easing: _options.easing || 'easeInOutBack'
    });
  }

  /**
   * Init the indicator
   */
  this.init = active => {
    // Select elements
    containerEl = typeof _container === 'string' 
      ? document.querySelector(_container) 
      : _container;

    indicatorEl = containerEl.querySelector('.indicator');

    // Add basic styling to the indicator
    addStyle(indicatorEl, {
      position: 'absolute',
      top: 0,
      left: 0,
      pointerEvents: 'none',
      // opacity: 0
    });

    // Attach click handler to items
    containerEl.childNodes.forEach(child => {
      child.addEventListener('click', () => {
        this.update(child);
      })
    })

    let activeEl = typeof active === 'string' 
      ? containerEl.querySelector(active) 
      : active;

    if (! activeEl) return;

    // Initial selected item
    this.update(activeEl)

    // Adjust indicator position on window resizing
    window.addEventListener('resize', () => {
      updatePosition(activeEl, false);
    });
  }

  /**
   * Update classes and position
   */
  this.update = active => {
    // if (! active) return;

    let indicated = active 
      ? active 
      : containerEl.querySelector('.indicated');

    indicated.classList.remove('indicated');

    active.classList.add('indicated');

    updatePosition(active);
  }
}

/**
 * Shorthand
 */
function indicate(container, options = {}, active = '.indicated') {
  let indicator = new Indicator(container, options);

  indicator.init(active);

  return indicator;
}

export {Indicator, indicate};