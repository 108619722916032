import Page from 'Page';
import Section from 'Section';
import Card from 'elements/Card';
import List from 'elements/List';
import Small from 'elements/Small';

export default function() {
  let delayTime = 0;
  let delay = (increment = 0) => delayTime +=increment;

  return (
    <Page id="services" title="Our Services" description="List of our services.">
      <Section>
        <div className="row gx-lg-5">
          <div className="col-12 col-md-6 col-xxxl-4 mb-4 mb-lg-5">
            {/* Card */}
            <Card title="Design & Development" icon="imgs/icons/layout.svg">
              <List delay={delayTime + 500} items={[
                <div>
                  Informative Website
                  <Small>Responsive, SEO friendly with RTL support (English | Arabic).</Small>
                </div>,
                <div>
                  E-Commerce Website
                  <Small>With Payment Integration if needed (Benefit, CrediMax, etc).</Small>
                </div>,
                'Other Solutions'
              ]} />
            </Card>
          </div>
          <div className="col-12 col-md-6 col-xxxl-4 mb-4 mb-lg-5">
            {/* Card */}
            <Card title="Web & Email Hosting" icon="imgs/icons/server.svg" delay={delay(100)}>
              <List delay={delayTime + 500} items={[
                '10 GB SSD Storage',
                'Unlimited Bandwidth',
                '10 Emails',
                'Free SSL',
                'Automated Backup',
                <div><img src="imgs/cpanel.png" height="12px" className="mb-1 me-1" alt="cPanel" />Access</div>,
                'Customer Support'
              ]} />
            </Card>
          </div>
          <div className="col-12 col-xxxl-4 mb-4 mb-lg-5">
            {/* Card */}
            <Card title="Support" icon="imgs/icons/toolbox.svg" delay={delay(100)}>
              <p className="mb-4" data-transition="fade-up" data-transition-delay={delayTime + 500}>
                If you need to make some changes to your existing website, We offer a meter based model where you get charged (per hour) as you go.
              </p>
            </Card>
          </div>
        </div>
      </Section>
    </Page>
  )
}