import {useEffect, useState, useRef} from 'react';
import anime from 'animejs/lib/anime.es.js';
import Glide from '@glidejs/glide/dist/glide.min.js';
import '@glidejs/glide/dist/css/glide.core.min.css';
import items from 'json/sliderItems';
import settings from 'json/sliderSettings';
import IconBubble from 'elements/IconBubble';

export default function ({className, delay, ...rest}) {
  delay = delay || 0;
  let slider = useRef(null);
  let slides = useRef([]);
  let [activeSlide, setActiveSlide] = useState(null);

  if (activeSlide !== null) {
    slides.current.push(activeSlide);
    // let set = new Set(slides.current);
    // set.add(activeSlide);

    // slides.current = [...set];
  }

  // if (slides.current.length === 4) {
  //   slides.current.shift();
  // }

  let hideIconBubble = () => {
    anime({
      targets: slider.current.querySelectorAll('.icon-bubble:not(:first-child)'),
      opacity: 0,
      rotate: '.25turn',
      easing: 'easeInSine',
      duration: 500,
      delay: 500,
      bottom: '-100px',
    });
  }

  useEffect(() => {
    let glide = new Glide('#slider', settings);
    let highlight = slider.current.querySelector('.highlight');

    glide.on(['build.after', 'resize'], () => {
      highlight.style.width = slider.current.querySelector('.glide__slide').offsetWidth+'px';
    });

    // Before transitioning
    glide.on('run', () => {
      // let index = slides.current.indexOf(glide.index);

      // if (index > -1) {
      //   slides.current.splice(index, 1);
      // }

      hideIconBubble();
      setActiveSlide(glide.index);
    });
      // setActiveSlide(glide.index);

      // let bubble = slider.current.querySelectorAll('.icon-bubble:nth-child(n+1)');

      // anime({
      //   targets: bubble,
      //   opacity: 0,
      //   rotate: '.25turn',
      //   easing: 'easeInSine',
      //   duration: 500,
      //   delay: 500,
      //   bottom: '-60px',
      // });

      // if (bubble.classList.contains('animating')) {
      //   bubble.style.opacity = 0;

      //   return;
      // }

      // anime({
      //   targets: bubble,
      //   duration: 1200,
      //   opacity: 0,
      //   left: 0,
      //   bottom: 0,
      //   translate: '-100% 0',
      //   rotate: -1 * (highlight.offsetWidth / (slider.current.querySelector('.icon-bubble').offsetWidth * Math.PI)) + 'turn',
      //   easing: "easeInBack",
      //   // update: anim => {
      //     // if (anim.progress < 70) return

      //     // if (highlight.style.overflow === 'hidden') return;

      //     // highlight.style.overflow = 'hidden';
      //   // },
      // });
    //;

    // After transitioning
    // glide.on('run', () => {
      // slides.current.push(glide.index);
      // highlight.style.overflow = 'initial';
    // });

    glide.mount();

    setActiveSlide(glide.index);
    // slides.current.push(glide.index);

    // Cleanup (event handlers) to prevent memory leak
    return () => {
      glide.destroy();
    }
  }, []);

  return (
    <div 
      ref={slider}
      id="slider"  
      className={(className ? className + ' ' : '') + "slider glide"} 
      style={{position: 'initial'}}
      {...rest}
    >
      <div 
        className="highlight position-absolute top-0 start-0 h-100 border" 
        data-transition-in="zoomOut" data-transition-delay={delay + 800} data-transition-easing="easeIn" data-transition-duration={400}
      >
        <div 
          data-glide-el="controls" 
          className="controls d-inline-block float-end m-4 position-relative z-index-2"
          data-transition-in="right" data-transition-delay={delay+600}
        >
          <button className="btn text-body-color p-2 rounded-circle" data-glide-dir="<">
            <i className="bi bi-arrow-left fs-4"></i>
          </button>
          <button className="btn text-body-color p-2 rounded-circle" style={{marginInlineStart: '-1px'}} data-glide-dir=">">
            <i className="bi bi-arrow-right fs-4"></i>
          </button>
        </div>
        {/*items[activeSlide]?.icon && 
          <IconBubble key={activeSlide} icon={items[activeSlide].icon} delay={activeSlide === 0 ? 1600 : 0} />
        */}
        {slides.current.map((index, key) =>
          <IconBubble key={items[index].title} icon={items[index].icon} delay={key === 0 ? 1600 : 500} onscreen={false} />
        )}
      </div>
      <div 
        className="glide__track mt-6" data-glide-el="track" 
        data-transition="right" data-transition-easing="easeOutBack" data-transition-delay={delay+550} data-transition-duration={650}
      >
        <ul className="glide__slides">
          {items.map((item, key) =>
            <li key={item.title} className="glide__slide p-4 pe-5 p-sm-5 mt-auto">
              <h4 className="text-color-1">{item.title}</h4>
              <div className="bg-color-1 mb-3" style={{height: 'var(--indicator-width)', width: '35px'}}></div>
              <p>{item.text}</p>
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}