import {useEffect, useRef} from 'react';
import IconBubble from 'elements/IconBubble';

export default function({className, title, children, icon, number, delay, ...rest}) {
  delay = delay || 0;

  return (
    <div 
      className={"card h-100 d-flex flex-column position-relative p-4 p-md-5 border" + (className ? ' ' + className : '')} 
      data-transition="right" data-transition-delay={delay} data-transition-out="right"
      {...rest} 
    >
      {title &&
        <h3 
          className="card-title mb-0 mb-4 mb-md-5"
          data-reveal data-reveal-delay={delay + 500}
        >
          {title}
          {/*<div 
            className="mt-2"
            style={{background: 'var(--indicator-color)', height: '1px', width: '100%'}}
            data-transition="left" data-transition-delay={1000}
          >
          </div>*/}
        </h3>
      }
      {children}
      {icon && 
        <IconBubble icon={icon} delay={delay + 1000} />
      }
    </div>
  )
}