import {useEffect, useRef} from 'react';
import anime from 'animejs/lib/anime.es.js';
import SVG from 'react-inlinesvg';
import onScreen from 'libraries/onScreen';

export default function({icon, line, delay, style, onscreen}) {
  let bubble = useRef(null);
  line = line ?? true;
  onscreen = onscreen ?? true;

  let animate = () => {
    anime({
      targets: bubble.current,
      // translateY: [-200, 0],
      bottom: ['200px', '-10px'],
      opacity: {value: 1, easing: 'linear', duration: 250},
      rotate: ['-.5turn', 0],
      delay: delay || 0,
      duration: 1600,
      easing: 'easeOutBounce',
      // begin: () => {
      //   bubble.current.classList.add('animating');
      // } ,
      // complete: () => {
      //   bubble.current.classList.remove('animating');
      // }
    });
  }

  useEffect(() => {
    if (! onscreen) {
      animate();

      return;
    }

    onScreen.is(bubble.current, () => {
      animate();
    });
  }, []);

  return (
      <div 
        ref={bubble}
        className="icon-bubble position-absolute d-flex flex-center ms-n2 ms-lg-0 bg-body-bg border border-color-1 rounded-circl"
        style={{
          height: '60px',
          width: '60px',
          left: 'calc(100% - 45px)',
          bottom: '-10px',
          // transform: 'translateX(-50%)',
          // background: 'linear-gradient(-45deg, rgba(var(--color-1-rgb), 1), rgba(var(--color-1-rgb), .75))',
          opacity: 0,
          ...style
        }}
      >
        <SVG src={icon} width="100%" height="100%" className="icon"
          style={{
            height: '30px',
            width: '30px',
          }}
        />
      </div>
  )
}