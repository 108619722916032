import {useEffect, useState, useRef} from 'react';
import Navigation from './Navigation';
import 'bootstrap/js/dist/collapse.js';
import DarkModeSwitch from 'DarkModeSwitch';
import {Link} from 'react-router-dom';
import Transition from 'libraries/transition/transition';
import 'libraries/transition/style.css';
import anime from 'animejs/lib/anime.es.js';

export default function({darkMode}) {
  let [isMenuOpen, setIsMenuOpen] = useState(false);
  let toggler = useRef(null);

  let toggleMenu = e => {
    setIsMenuOpen(! isMenuOpen);
  }

  useEffect(() => {
    Transition.init(toggler.current.querySelector('.open'), isMenuOpen, {onScreen: false});
    Transition.init(toggler.current.querySelector('.close'), ! isMenuOpen, {onScreen: false});

    if (isMenuOpen) {
      anime({
        targets: document.querySelectorAll('#navbar .nav-item'),
        translateY: [-40, 0],
        opacity: [0, 1],
        delay: anime.stagger(75),
        duration: 1200,
        easing: 'easeOutBounce'
      });
    }
  }, [isMenuOpen]);

  return (
    <div 
      className="site-sidebar border-end d-md-flex flex-xl-column bg-body-bg overflow-hidden" 
    >
      {/* Sidebar Header */}
      <header className="sidebar-header d-flex justify-content-md-center align-items-center p-4 position-relative">

        {/* Logo */}
        <Link to="/">
          <img src="imgs/logo2.svg" width="160" className="logo filter-body-color" />
        </Link>

        {/* Navbar Toggler */}
        <button 
          ref={toggler}
          className="navbar-toggler ms-auto d-flex flex-center d-md-none position-relative" 
          type="button" 
          data-bs-toggle="collapse" data-bs-target="#navbar" 
          aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation"
          onClick={toggleMenu}
        >
          <span className="open">
            <i className="bi bi-list fs-1" data-transition="right" data-transition-duration={1000} data-transition-out="right"></i>
          </span>
          <span className="close position-absolute top-0 start-0 d-flex flex-center">
            <i className="close bi bi-x-lg fs-3" data-transition="right" data-transition-duration={1000} data-transition-out="right"></i>
          </span>
        </button>
      </header>

      {/* Sidebar Body */}
      <div className="sidebar-body flex-grow-1 d-flex align-items-center justify-content-md-end py-0 py-xl-4 px-3 mb-md-0">
        <Navigation darkMode={darkMode} />
      </div>

      {/* Sidebar Footer */}
      <footer className="sidebar-footer d-none d-md-flex justify-content-end align-items-center p-4">
        <DarkModeSwitch darkMode={darkMode} />
      </footer>
    </div>
  )
}