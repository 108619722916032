import {useEffect, useRef} from 'react';
// import ScrollHint from 'ScrollHint';
import Transition from 'libraries/transition/transition';
import 'libraries/transition/style.css';
import Reveal from 'libraries/reveal/reveal';
import 'libraries/reveal/style.css';

export default function({className, title, description, children, ...rest}) {
  let page = useRef(null);

  // Page title
  document.title = title || 'Blank Canvas | Web Design & Development';

  // Page description
  document.querySelector('[name="description"]').content = description;

  // Canonical link
  document.querySelector('link[rel="canonical"]').href = window.location.href.replace('www.', '');

  useEffect(() => {
    // Transition elements in
    Transition.init(page.current, false);

    // Reveal text
    Reveal.init('article', {
      color: 'var(--body-color)',
    });
  }, []);

  return (
    <article 
      ref={page}
      className={className ? className + ' ' : '' + "position-absolute top-0 start-0 w-100"} 
      {...rest}
    >
      {title
        ? <header>
            <h1 
              className="position-relative mb-0 text-color-1 px-4 pt-5 pb-4 px-lg-6 pt-md-6 pb-md-5 pt-xl-7 pb-xl-5 px-xxxl-8 pb-xxxl-6 pt-xxxl-8" 
              data-transition="up" data-transition-duration={1000}
              style={{
                letterSpacing: '12px',
              }}
            >
              {title}
            </h1>
          </header>
        : ''
      }
      {children}
    </article>
  )
}