import Page from 'Page';
import Section from 'Section';
import Link from 'elements/Link';
import Slider from 'Slider'

export default function (props) {
  return (
    <Page id="home" description="For Modern Web Design and Development in Bahrain.">
      <Section className="d-flex align-items-xxl-center pe-xxxl-0">
        <div className="row w-100 gx-0">
          <div className="col-12 col-xxl-5 pe-0 pe-xxl-6 pe-xxxl-8 mb-5 my-xxl-auto">
            <h4 className="text-color-1 mb-3 mt-3 mt-xl-0" data-reveal data-reveal-delay={50} data-transition-out="up">
              We. Are.
            </h4>
            <h1 className="display-2 mb-4" data-reveal data-reveal-layers={2} data-reveal-delay={100} data-transition-out="up" data-transition-delay={50}>
              Re-envisioning <br/>The Web.
            </h1>
            <p data-reveal data-reveal-layers={2} data-reveal-delay={150} data-transition-out="up" data-transition-delay={100}>
              Welcome to Blank Canvas - a team of passionate people about web design and development based in Bahrain.
            </p>
            <p data-reveal data-reveal-layers={2} data-reveal-delay={200} data-transition-out="up" data-transition-delay={150}>
              We offer a variety of web services that include designing and developing elegant, responsive and multilingual websites, 
              building E-Commerce stores, Creating Portals, and Integrating Web APIs. <br/>
            </p>
            <div className="row gx-0 mt-4" data-transition-out="up" data-transition-delay={200}>
              <div className="col-auto me-3" data-transition-in="right" data-transition-delay={250}>
                <Link color="color-1" text="Contact" icon="bi bi-telephone" to="/contact" />
              </div>
              <div className="col-auto" data-transition-in="right" data-transition-delay={300}>
                <Link color="color-1" text="Services" icon="bi bi-arrow-right" iconPosition="end" to="/services" />
              </div>
            </div>
          </div>
          <div className="col-12 col-xxl-7 d-flex align-items-end position-relative" data-transition-out="zoomIn">
            <Slider delay={500} />
          </div>
        </div>
      </Section>
    </Page>
  )
}