import anime from 'animejs/lib/anime.es.js';
import onScreen from 'libraries/onScreen';
import animations from 'libraries/transition/animations';
import easing from 'libraries/transition/easing';

/**
 * Options
 */
let defaultOptions = {
  duration: 1500,
  delay: 0,
  easing: 'easeInOutBack',
}

/**
 * Transition
 */
function init(container, out = false, options = {}) {
  options = {...defaultOptions, ...options};

  let [selector, type] = out
    ? ['[data-transition], [data-transition-out]', 'Out']
    : ['[data-transition], [data-transition-in]', 'In']

  container = typeof container === 'string'
    ? document.querySelector(container)
    : container

  container.querySelectorAll(selector).forEach(element => {
    let data = element.dataset;
    let transition = data['transition'+type] || data.transition;
    let keyframes = animations[type.toLowerCase()][transition] || [{}, {}];

    // fade
    keyframes[1].opacity = out ? 0 : 1;

    let timing = {
      duration: Number(data['transitionDuration'+type] || data.transitionDuration || options.duration),
      delay: Number(data['transitionDelay'+type] || data.transitionDelay || options.delay),
      easing: easing[data['transitionEasing'+type] || data.transitionEasing || options.easing],
      fill: 'forwards'
    };

    if (options.onScreen == false) {
      element.animate(keyframes, timing);

      return;
    }

    onScreen.is(element, () => {
      element.animate(keyframes, timing);
    });
  });
}

export default {init};