export default function({title, before, after, className, titleClassName, subClassName}) {
  let getSub = (text, delay) => {
    return (
      <h5 
        className={(subClassName ? subClassName + ' ' : '') + "position-relative mb-0 text-color-1"}
        data-transition-in="right" data-transition-delay-in={delay} data-transition-easing-in="easeOutBack"
        data-transition-out="left" data-transition-easing-out="easeInBack"
        data-transition-duration={1000}
        style={{
          fontWeight: '100', 
          marginBottom: '7px',
        }}
      >
        {text}
      </h5>
    )
  }

  return (
    <div 
      className={(className ? className + ' ' : '') + "position-relative mb-4 mb-xl-5 d-flex flex-column align-items-start"}
    >
      {before && getSub(before, 500)}
      <h1 
        className={(titleClassName ? titleClassName + ' ' : '') + "mb-0"}
        data-reveal data-reveal-delay={200}
        data-transition-out="right"
      >
        {title}
      </h1>
      {after && getSub(after, 1000)}
    </div>
  )
}