import {Link} from 'react-router-dom';

export default function({type, className, color, text, icon, iconPosition, size, ...rest}) {
	return (
		<button 
      type={type || 'button'}
      className={
        (className ? className + ' ' : '')
        + 'btn' 
        + (size ? ' btn-' + size : '') 
        + (color ? ' btn-underline-' + color : '') 
        + ' d-inline-flex align-items-center' 
        + (iconPosition === 'end' ? ' flex-row-reverse' : '') 
      } 
      {...rest}
    >
      <i 
        className={icon + (iconPosition === 'end' ? ' pe-0 ' : ' ps-0 ') + (size ? 'bi-' + size : '')}
        style={icon ? iconPosition === 'end' ? {paddingInlineStart: 'var(--btn-padding-x)'} : {paddingInlineEnd: 'var(--btn-padding-x)'} : ''}
      ></i>
		  {text || ''}
		</button>
	)
}