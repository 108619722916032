import {TransitionGroup, CSSTransition} from 'react-transition-group';
import {Route, Routes, useLocation} from "react-router-dom";
import Home from 'pages/Home';
import About from 'pages/About';
import Services from 'pages/Services';
import Contact from 'pages/Contact';
import NoMatch from 'pages/404';
import Transition from 'libraries/transition/transition';
import 'libraries/transition/style.css';

export default function() {
  let location = useLocation();

  let scrollToTop = () => {
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
    });
  }

  let transitionOut = container => {
    Transition.init(container, true);
  }

  return (
    <main className="site-body position-relative">
      <TransitionGroup component={null}>
        <CSSTransition 
          key={location.key} 
          timeout={1500} 
          onEntering={scrollToTop}
          onExiting={transitionOut}
        >
          <Routes location={location}>
            <Route path="/services" element={<Services />}/>
            <Route path="/about" element={<About /> }/>
            <Route path="/Contact" element={<Contact />}/>
            <Route path="/" element={<Home />}/>
            <Route path="*" element={<NoMatch />}/>
          </Routes>
        </CSSTransition>
      </TransitionGroup>
    </main>
  )
}