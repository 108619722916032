import {useEffect, useState, useRef} from 'react';
import {indicate} from 'libraries/indicator';
import List from 'elements/List';
import Button from 'elements/Button';
import Card from 'elements/Card';
import Small from 'elements/Small';
// import _ from 'lodash';

export default function() {
  let packages = useRef(null);
  let indicator = useRef(null);

  let initialValues = {
    package: 'standard',
  };

  let [inputData, setInputData] = useState(initialValues);
  let [response, setResponse] = useState({});

  let delayTime = 0;
  let delay = (increment = 0) => delayTime +=increment;

  let handleSubmit = e => {
    e.preventDefault();

    setResponse({result: 'sending'});

    // Submit data
    fetch(`${window.location.protocol}//${window.location.host}/api/inquire.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(inputData),
    })
    .then(response => response.json())
    .then(result => {
      setResponse(result);

      // Reset form if email is successfully sent.
      if (result.result === 'success') {
        setInputData(initialValues);
      }
    })
    .catch(result => {
      console.error('Error:', result);
      setResponse(result);
    });
  }

  let handleChange = e => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    const name = e.target.name;

    // If we select a custom option, select the custom package options automatically.
    if (['development', 'domain', 'hosting'].indexOf(name) !== -1) {
      setInputData(values => ({...values, package: 'custom', [name]: value}))
    }

    // If we swicth away from custom package, reset custom options checkboxes.
    if (name === 'package' && ['standard', 'support'].indexOf(value) !== -1) {
      setInputData(values => ({...values, 'development': false, 'domain': false, 'hosting': false, [name]: value}))
    }

    setInputData(values => ({...values, [name]: value}));
  }

  useEffect(() => {
    let indicatedPackage = packages.current.querySelector(`[name="package"][value="${inputData.package}"]`).parentElement;

    if (indicator.current) {
      indicator.current.update(indicatedPackage);

      return;
    }

    indicator.current = indicate(packages.current, {}, indicatedPackage);
  }, [inputData.package]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12 col-xxxl-8" data-transition="right">
          <div className="row">
            <div className="col-12 col-md-4 mb-3">
              <input 
                type="text" name="name" placeholder="Name" aria-label="Name" required
                className="form-control"
                value={inputData.name || ''}
                onChange={handleChange} 
              />
            </div>
            <div className="col-12 col-md-4 mb-3">
              <input 
                type="text" name="email" placeholder="Email" aria-label="Email" required
                className="form-control"
                value={inputData.email || ''}
                onChange={handleChange} 
              />
            </div>
            <div className="col-12 col-md-4 mb-3">
              <input 
                type="text" name="phone" placeholder="Phone" aria-label="Phone" required
                className="form-control" 
                value={inputData.phone || ''}
                onChange={handleChange} 
              />
            </div>
            <div className="col-12 mb-5">
              <div className="form-floating">
                <textarea 
                  name="message" placeholder="Leave a message" 
                  id="message" 
                  className="form-control"
                  style={{height: '100px'}}  
                  value={inputData.message || ''}
                  onChange={handleChange} 
                />
                <label htmlFor="message">Message</label>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12" data-transition="right" data-transition-delay={100}>
          <p className="mb-4">Select your package:</p>
          <div className="packages row gx-lg-5 position-relative" ref={packages}>
            <div className="indicator" data-transition data-transition-delay={1000}>
              <div 
                className="h-100 position-relative" 
                style={{
                  border: 'var(--indicator-width) solid var(--indicator-color)', 
                  pointerEvents: 'none', 
                }}
              >
              </div>
            </div>
            <div className="col-12 col-md-6 col-xxxl-4 mb-4 mb-lg-5">
              <input 
                name="package" value="standard" type="radio" 
                id="card1" 
                className="d-none"
                checked={inputData.package === 'standard'}
                onChange={handleChange} 
              />
              <label className="d-block h-100" htmlFor="card1">
                <Card title="Standard Package" icon="imgs/icons/tick.svg">
                  <p className="mb-4" data-transition="fade-up">Take care of everything for me.</p>
                  <List delay={delayTime + 500} items={[
                    'Website Design & development',
                    'Domain Booking',
                    'Web & Email Hosting',
                    <div>
                      3 Months Free Support
                      <Small>Security Updates and Bug fixes.</Small>
                    </div>
                  ]} />
                </Card>
              </label>
            </div>
            <div className="col-12 col-md-6 col-xxxl-4 mb-4 mb-lg-5">
              <input 
                name="package" value="support" type="radio" 
                id="card2" 
                className="d-none"
                checked={inputData.package === 'support'}
                onChange={handleChange} 
              />
              <label className="d-block h-100" htmlFor="card2">
                <Card title="Support Package" icon="imgs/icons/brush.svg" delay={delay(100)}>
                  <div data-transition="fade-up">
                    <p className="mb-4">I just need to implement some changes to my existing website.</p>
                    <List delay={delayTime + 500} items={[
                      'You will be charged per hour.',
                      'You will be informed in advance about the total time required to finish the task.',
                    ]} />
                  </div>
                </Card>
              </label>
            </div>
            <div className="col-12 col-md-6 col-xxxl-4 mb-4 mb-lg-5">
              <input 
                name="package" value="custom" type="radio" 
                id="card3" 
                className="d-none"
                checked={inputData.package === 'custom'}
                onChange={handleChange} 
              />
              <label className="d-block h-100" htmlFor="card3">
                <Card title="Custom" icon="imgs/icons/grid.svg" delay={delay(100)}>
                  <p className="mb-4" data-transition="fade-up">Select what you need:</p>
                  <List className="list-unstyled" delay={delayTime + 500} items={[
                    <div className="form-check">
                      <input 
                        name="development" type="checkbox" 
                        id="check1" 
                        className="form-check-input" 
                        checked={inputData.development || false}
                        onChange={handleChange} 
                        />
                      <label className="form-check-label" htmlFor="check1">
                        Web design & development
                      </label>
                    </div>,
                    <div className="form-check">
                      <input 
                        name="domain" type="checkbox" 
                        id="check2" 
                        className="form-check-input" 
                        checked={inputData.domain || false}
                        onChange={handleChange} 
                      />
                      <label className="form-check-label" htmlFor="check2">
                        Domain Booking
                      </label>
                    </div>,
                    <div className="form-check">
                      <input 
                        name="hosting" type="checkbox" 
                        id="check3" 
                        className="form-check-input" 
                        checked={inputData.hosting || false}
                        onChange={handleChange} 
                      />
                      <label className="form-check-label" htmlFor="check3">
                        Web & Email Hosting
                      </label>
                    </div>,
                  ]} />
                </Card>
              </label>
            </div>
          </div>
        </div>
        <div className="col-12 col-xxxl-8 d-flex align-items-center justify-content-end">
          {response.result 
            ? <small className="me-4 d-inline-flex align-items-center">
                {response.result === 'success'
                  ? <><i className="bi bi bi-envelope-check me-2 text-success"></i>Sent.</>
                  : response.result === 'sending'
                    ? <><i className="bi bi-envelope me-2"></i>Sending..</>
                    : <><i className="bi bi-envelope-x me-2 text-danger"></i>Failed.</>
                }
              </small>
            : ''
          }
          <Button type="submit" color="color-1" text="Send" icon="bi bi-arrow-right" iconPosition="end" />
        </div>
      </div>
    </form>
  )
}