import Page from 'Page';
import Section from 'Section';
import Heading from 'elements/Heading';
import Card from 'elements/Card';
import ContactForm from 'ContactForm';
import channels from 'json/contactChannels';

export default function() {
  let delay = 0;
  let increaseDelay = () => delay += 100;

  return (
    <Page id="contact" title="Contact Us" description="Get in touch with us through mobile, whatsapp, email, or our contact form.">

      {/* Contact Details Section */}
      <Section>
        <Heading title="Contact Channels" before="Our" />
        <div className="row gx-lg-5">
          {channels.map(channel => 
            <div key={channel.title} className="col-12 col-md-6 col-xxxl-4 mb-4 mb-lg-5">
              <Card icon={channel.icon} delay={increaseDelay()}>
                <h4 data-reveal data-reveal-delay={delay + 500}>
                  {channel.title}
                </h4>
                <a 
                  target={channel.target} href={channel.link}
                  data-transition="right"
                >
                  {channel.text}
                </a>
              </Card>
            </div>
            
          )}
        </div>
      </Section>

      {/* Contact Form Section */}
      <Section>
        <Heading title="Let's Talk" />
        <ContactForm />
      </Section>
    </Page>
  )
}