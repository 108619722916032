import {useEffect, useRef, useState} from "react";
import {useLocation, NavLink} from "react-router-dom";
import anime from 'animejs/lib/anime.es.js';
import {indicate} from 'libraries/indicator';
import links from 'json/navigationLinks';
import DarkModeSwitchMobile from 'DarkModeSwitchMobile';
import Tooltip from 'bootstrap/js/src/tooltip';

export default function({darkMode}) {
  let location = useLocation();
  let navigation = useRef(null);
  let indicator = useRef(null);

  let getNavItemIcon = link => location.pathname === link.to ? link.activeIcon : link.icon;

  let initTooltips = () => {
    navigation.current.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(el => {
      new Tooltip(el)
    });
  }

  let animateItems = () => {
    anime({
      targets: navigation.current.querySelectorAll('.nav-item'),
      translateY: [(el, i) => i * 70 + 70, 0],
      opacity: [0, 1],
      delay: anime.stagger(100, {start: 250}),
      duration: 2000,
    });
  }

  useEffect(() => {
    if (indicator.current) {
      indicator.current.update(navigation.current.querySelector('.indicated'));

      return;
    };

    indicator.current = indicate('#navbar ul', {easing: 'easeOutElastic', duration: 2500});

    animateItems();
    initTooltips();
  }, [location]);

  return (
    <nav className="navbar navbar-expand-md w-100">
      <div className="collapse navbar-collapse flex-row" id="navbar">
        <ul className="navbar-nav flex-row flex-xl-column ms-md-auto me-auto me-md-0 position-relative" ref={navigation}>
          <div className="indicator" style={{position: 'absolute', width: '48px', height: '48px'}}>
            {/* desktop */}
            <div className="position-absolute top-50 end-0 d-none d-xl-block" 
              style={{
                width: 'var(--indicator-width)',
                height: '35px',
                background: 'var(--indicator-color)',
                marginInlineEnd: '-1.5rem',
                transform: 'translateY(-50%)'
              }}
            >
            </div>
            {/* tablet */}
            <div className="position-absolute bottom-0 start-50 d-none d-md-block d-xl-none" 
              style={{
                height: 'var(--indicator-width)',
                width: '35px',
                background: 'var(--indicator-color)',
                transform: 'translateX(-50%)',
                marginBottom: '-20px',
              }}
            >
            </div>
          </div>
          {links.map((link) =>
            <li 
              key={link.to} 
              className={(location.pathname === link.to ? 'indicated ' : '') + 'nav-item me-3 me-md-3 me-xl-2 my-md-2 py-3 py-md-0'}
            >
              <NavLink 
                className="nav-link" 
                data-bs-toggle="tooltip" data-bs-placement="right" data-bs-trigger="hover" data-bs-offset="[0, 42]"
                aria-current="page" 
                title={link.title} 
                to={link.to} 
                style={{
                  height: '3rem', 
                  width: '3rem'
                }}
              >
                <i className={'bi-lg position-absolute top-50 start-50 translate-middle ' + getNavItemIcon(link)} ></i>
              </NavLink>
            </li>
          )}

          {/* Mobile Switch */}
          <DarkModeSwitchMobile darkMode={darkMode} />
        </ul>
      </div>
    </nav>
  )
}