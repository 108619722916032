import Page from 'Page';
import Section from 'Section';
import Heading from 'elements/Heading';
import Link from 'elements/Link';

export default function() {
  return (
    <Page title="404">
      <Section>
        <h1 className="display-2 d-inline-block" data-transition="up" data-transition-out="up">Whoopsies!</h1>
        <p data-transition="up" data-transition-out="up" data-transition-delay={100}>We can't seem to find the page your're looking for.</p>
        <Link color="color-1" text="Go Home" icon="bi bi-house-door" to="/" data-transition="up" data-transition-out="up" data-transition-delay={200} />
      </Section>
    </Page>
  )
}