import Page from 'Page';
import Section from 'Section';
import Heading from 'elements/Heading';
import TeamMembers from 'TeamMembers';
import BoxListItem from 'elements/BoxListItem';
import whyUsItems from 'json/whyUs';
import List from 'elements/List';
import intro from 'json/aboutIntro';

export default function() {
  let delay = 0;

  return (
    <Page id="about" title="About Us" description="Learn about Blank Canvas and the team behind it.">

      {/* Identity Section */}
      <Section>
        <Heading title={intro.title} before={intro.before} />
        <div className="row">
          <div className="col-auto" style={{flexBasis: '750px'}}>
            <p data-transition="up" data-transition-delay={200}>
              {intro.text}
            </p>
          </div>
        </div>
      </Section>

      {/* Team Members Section */}
      <Section>
        <Heading title="Team" before="Our" />
        <TeamMembers />
      </Section>

      {/* Why Us Section */}
      <Section>
        <Heading title="Why" after="Us" />
        <div className="row gx-lg-6">
          {whyUsItems.map((item, key) =>
            <div key={key} className="col-12 col-md-6 col-xxl-4 mb-5">
              <BoxListItem icon={item.icon} number={key + 1} title={item.title} text={item.text} delay={delay +=100} />
            </div>
          )}
        </div>
      </Section>
    </Page>
  )
}