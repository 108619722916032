/**
 * options
 */
let observerOptions = {
  rootMargin: `0px 0px -10% 0px`
}

/**
 * Private variables
 */
let actions = {};

/**
 * Observer
 */
let observer = new IntersectionObserver((entries, observer) => {
  entries.forEach(entry => {
    if (! entry.isIntersecting) return;

    actions[entry.target.dataset.onscreenRef].call();

    observer.unobserve(entry.target);
  });
}, observerOptions);

/**
 * Attach element to the observer
 */
function is(element, callback) {
  if (typeof element === 'string') {
    element = document.querySelector(element);
  }

  let ref = Math.random().toString().substr(2);

  actions[ref] = callback;

  element.setAttribute('data-onscreen-ref', ref);

	observer.observe(element);
}

export default {is, observerOptions};