import {useEffect, useState, useRef} from 'react';
import Sidebar from 'Sidebar';
import Main from 'Main';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'scss/main.scss';
import Cookies from 'js-cookie';

export default function() {
  let init = useRef(false);
  let [darkMode, setDarkMode] = useState(
    Cookies.get('darkMode') || 
    matchMedia('(prefers-color-scheme: dark)').matches.toString()
  );

  document.body.setAttribute('data-dark-mode', darkMode);

  useEffect(() => {
    if (init.current) {
      Cookies.set('darkMode', darkMode);
    }

    init.current = true;
  }, [darkMode]);

  return (
    <div className="site">
      <Sidebar darkMode={[darkMode, setDarkMode]} />
      <Main />
    </div>
  );
}