import {useEffect, useRef} from 'react';
import {indicate} from 'libraries/indicator';

export default function(props) {
  let nav = useRef(null);
  let [darkMode, setDarkMode] = props.darkMode;

  useEffect(() => {
    indicate(nav.current, {easing: 'easeOutElastic'});
  }, []);

  return (
    <div className="dark-mode-switch">
      <div className="d-flex align-items-center">
        <i className="bi-lg bi-moon d-block me-3 text-body-color" />
        <ul className="dark-mode nav position-relative ff-default" ref={nav}>
          <div className="indicator" style={{height: '40px', width: '20px', top: 0, left: 0}}>
            <div 
              className="position-absolute start-50 bottom-0 translate-middle-x" 
              style={{
                width: '10px', 
                height: 'var(--indicator-width)', 
                background: 'var(--indicator-color)'
              }}
            >
            </div>
          </div>
          {['false', 'true'].map((value, key) => 
            <li 
              key={key}
              className={
                "d-flex flex-center p-2 position-relative" + 
                (darkMode === value ? ' indicated' : '')
              }
              style={{width: '20px', cursor: 'pointer'}} 
              onClick={() => setDarkMode(value)}
            >
              {key}
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}