export default function(props) {
  let [darkMode, setDarkMode] = props.darkMode;

  return (
    <div className="dark-mode-mobile form-switch d-flex flex-column flex-center d-md-none ms-auto ps-md-3 me-2 ps-0 position-relative">
      <label className="form-check-label" htmlFor="darkMode">
        <i className='bi-lightbulb'></i>
      </label>
      <input 
        className="form-check-input float-0 ms-0 mt-2" type="checkbox" role="switch"
        checked={darkMode === 'true'}
        onChange={() => { setDarkMode(darkMode === 'true' ? 'false' : 'true') }}
        style={{transform: 'scale(.75)'}}
      />
    </div>
  )
}